import { Quill } from "vue2-editor";

function getFontName(font) {
    return font.toLowerCase().replace(/\s/g, "-");
}

// Specify Quill fonts
const fontList = [
    "Arial",
    "Khmer OS",
    "Khmer Muol",
    "Courier",
    "Garamond",
    "Tahoma",
    "Times New Roman",
    "Verdana"
];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import("attributors/class/font");
var ColorClass = Quill.import("attributors/class/color");
fonts.whitelist = fontNames;
Quill.register(fonts, true);
Quill.register(ColorClass, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
    let fontName = getFontName(font);
    fontStyles +=
        ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" +
        fontName +
        "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" +
        fontName +
        "]::before {" +
        "content: '" +
        font +
        "';" +
        "font-family: '" +
        font +
        "', sans-serif;" +
        "}" +
        ".ql-font-" +
        fontName +
        "{" +
        " font-family: '" +
        font +
        "', sans-serif;" +
        "}";
});

const node = document.createElement("style");
node.innerHTML = fontStyles;
document.body.appendChild(node);

const customToolbar = [
    [{ font: fonts.whitelist }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline", "strike"],
    [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
    ],
    [{ header: 1 }, { header: 2 }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ color: [] }, { background: [] }],
    ["link"],
    [{ direction: "rtl" }],
    ["clean"]
];

export default customToolbar;
